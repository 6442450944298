// # lib
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/system";
import { MenuItem, Select, Checkbox } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import searchIcon from "../../assets/gusers-and-teams-gtable-search-icon.svg";
import searchActiveIcon from "../../assets/gusers-and-teams-gtable-search-active-icon.svg";
import filterDownArrow from "../../assets/gusers-and-teams-down-arrow.svg";
import { checkboxCheckedAll } from "../../utility-functions/assetLoader";
import { checkboxUncheck } from "../../utility-functions/assetLoader";

// # components
import GHeader from "../design/components/GHeader";
import Button from "../design/Button";
import GTable from "../design/components/GTable";
import VoiceBroadcastModal from "./VoiceBroadcastModal/VoiceBroadcastModal";
import GModal from "../design/components/GModal";
import WarnModal from "../contact-lists/WarnModal/WarnModal";
import Autodial2 from "./autodial2/Autodial2";
import AddContactModal from "./AddContactModal/AddContactModal";
import { Filters } from "../dashboard/Filters";

// # assets
import plus from "../../assets/campaigns-view-add-contacts-plus.svg";
import autodialIcon from "../../assets/autodial-icon.svg";
import autodialIconDisabled from "../../assets/autodial-icon-disabled.svg";
import threedotsIcon from "../../assets/gcall-logs-threedots-icon.svg";
import deleteIcon from "../../assets/error-contact-close.svg";
import saveIcon from "../../assets/error-contact-check.svg";
import {
  //callIcon,
  callLogsTableIncomingArrow,
  callLogsTableOutgoingArrow,
  contactListsViewTableCloseIcon,
  topNavAccountSelectedAccountTickIcon,
  sharedTablePhoneIcon,
  voiceBroadcastIcon,
  voicebroadcastFailedCallIcon as invalidContactIcon,
  //messageIcon,
} from "../../utility-functions/assetLoader";

// # css
import colors from "../design/colors";
import styles from "../design/styles";
import "./ContactListsView.css";

// # state
import { JService } from "../api-service/ApiService";
import { filtersReducer, getOrgURLParam } from "../../utility-functions";
import { setDialer } from "../features/dialer/dialer";
import { formatDate } from "../../utility-functions";
import { setReduxAutodialState } from "../features/autodial/autodial";
import commonStyles from '../design/commonStyles';
import { enqueueSnackbar } from 'notistack';
import { createPortal } from "react-dom";
import libphonenumber from "google-libphonenumber";
//import MessageHistoryModal from "../ActivityLogs/Sms/MessageHistoryModal";
import { CustomTooltip } from "../design/components/customComponents";
const initialState = {
  statusTypes: {
    all: [
      { id: 1, name: "Unattempted" },
      { id: 2, name: "Attempted, not reached" },
      { id: 3, name: "Reached Successfully" },
    ],
    selected: [],
  },
  assignedTo: {
    all: [],
    selected: [],
  },
  currentSet: "",
  searchText: "",
  allContacts: {
    selected: false,
  },
};

export default function ContactListsView() {
  const globalCampaignState = useSelector(
    (state) => state?.campaignReducer?.value
  );
  const reduxAccount=useSelector((state)=>state?.account?.value)
  const maskNumbers = reduxAccount?.data?.number_masking && reduxAccount?.data?.profile?.role !== 1;

  let filters = [{ name: "Call status", toggle: false, isSearch: true, payloadFor: 'statusTypes' }];

  if (reduxAccount?.data?.profile?.role === 1 || reduxAccount?.data?.profile?.role === 4) {
    filters.push({ name: "Assigned to", toggle: false, isSearch: true, payloadFor: 'assignedTo'});
  }
  const globalAutodialState = useSelector((state) => state?.autodial?.value);
  //const reduxVn=useSelector((state)=>state?.virtualNumber?.value);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: listId } = useParams();
  const location = useLocation();
  const contentTitle = useState(location?.state?.listTitle);
  
  const [rows, setRows] = useState([]);
  const [masterRows, setMasterRows] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showVoiceBroadcastModal, setShowVoiceBroadcastModal] = useState(false);
  //   const [autodial, setAutodial] = useState({
  //     minimized: false,
  //     maximized: false,
  //   });
  const [invalidContacts, setInvalidContacts] = useState([]);
  const [showInvalidContactsModal, setShowInvalidContactsModal] = useState(false);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [csvData, setCSVData] = useState({
    headers: [["Name"], ["Number"], ["Assigned to"], ["Last contacted"]],
    data: [],
    ids: [],
  });
  const [rowOptionsVisible, setRowOptionsVisible] = useState(false);
  const [rowBeingEdited, setRowBeingEdited] = useState(null);
  const [rowOptionsCords, setRowOptionsCords] = useState([0, 0]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAssignToModal, setAssignToModal] = useState(false);
  const [unassignModal, setUnassignModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [users, setUsers] =useState([])
  const [editContactName, setEditContactName] = useState("");
  const [editContactNumber, setEditContactNumber] = useState("");
  const [userSearchText, setUserSearchText] = useState('');
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [usersToDisplay, setUsersToDisplay]=useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    nextUrl: null,
    previousUrl: null,
    rowCount: 0,
  });
  const [pageSize, setPageSize] = useState(10);
  // const [messagingEnabled, setMessagingEnabled]=useState(false)
  // const [messageHistoryModal, setMessageHistoryModal]=useState({visible:false, id:null, candidateDetails:null, viewOnly:false, viewedMsgId:null})

  const [availableData, dispatchAvailableData] = useReducer(
    filtersReducer,
    initialState
  );
useEffect(() => {
  const controller = new AbortController();

  if (availableData.statusTypes) {
    setPageData((prev) => {
      const newPageData = { ...prev, currentPage: 1 };
      fetchData(controller.signal, 1);
      return newPageData; 
    });
  }

  return () => controller.abort(); 
}, [availableData.statusTypes]);

useEffect(() => {
  const controller = new AbortController();

  fetchData(controller.signal, pageData.currentPage);

  return () => controller.abort(); 
}, [
  pageData.currentPage,
  pageSize,
  availableData.searchText,
  availableData.allContacts,
  availableData.assignedTo,
]);

  useEffect(() => {
    setCSVData((prevState) => {
      let newIds = [];
      let newData = [];

      for (let i = 0; i < rows?.length; ++i) {
        let item = rows[i];
        if (!newIds?.includes(item?.id)) {
          newIds.push(item?.id);
          let last_contacted;
          if (item.last_contacted?.contacted_on) {
            last_contacted = formatDate(item.last_contacted.contacted_on);
          } else last_contacted = "-";
          let temp = [item?.name, ...maskNumbers? [] : [item?.number], (item?.assigned_to?.map(person => person.first_name) ?? []).join(', ') || 'None',  last_contacted];
          newData.push(temp);
        }
      }
      return { ...prevState, data: newData, ids: newIds, headers: [["Name"], ...maskNumbers ? [] : ["Number"], ["Assigned to"], ["Last contacted"]] };
    });
  }, [rows, maskNumbers]);

  const fetchData = (signal, page = 1) => {
    if (page < 1) return; 
    setShowLoader(true);
  
    const { statusTypes, searchText, assignedTo } = availableData;
    const searchQuery = searchText.trim();
  
    const queryParams = new URLSearchParams({
      page, 
      page_size: pageSize, 
      ...(searchQuery && { search: searchQuery }),
      ...(statusTypes?.selected?.length && {
        call_status: JSON.stringify(statusTypes.selected),
      }),
      ...(assignedTo?.selected?.length && !assignedTo.selected.includes("Unassigned") && {
        assigned_to: JSON.stringify(assignedTo.selected),
      }),
      ...(assignedTo?.selected.includes("Unassigned") && {
        assigned_to: JSON.stringify([]),
      }),
    });
  
    const url = `/api/v1/core/retrieve-contacts/${listId}/?${queryParams}`;
  
    JService.get(url, {}, { signal })
      .then((res) => {
        if (!res?.success) throw new Error("Failed to fetch contacts");
  
        const newResults = res.data.results.map((d, index) => ({
          ...d,
          _id: index + (page - 1) * pageSize,
        }));
  
        setRows(newResults);
        setMasterRows(newResults);
        setPageData({
          currentPage: page,
          nextUrl: res.data.next,
          previousUrl: res.data.previous,
          rowCount: res.data.count,
        });
      })
      .catch((err) => {
        const errorMessage = err?.message || "An error occurred while fetching data";
        const excludedMessages = ["Invalid page", "Failed to fetch"];
        if (!excludedMessages.some((msg) => errorMessage.includes(msg))) {
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    })
    .finally(() => setShowLoader(false));
  };
  
  
  
  const fetchInvalidContacts = () => {
    JService.get(`/api/v1/core/retrieve-invalid-contacts/${listId}/`)
      .then((res) => {
        if(!res?.success) throw new Error(res?.message)
		setInvalidContacts(res.data);
      })
      .catch((err) => enqueueSnackbar(err?.message || "Error occurred while fetching invalid contacts", { variant: "error"}))
  };
  
  const fetchUsers = () => {
    if ([1, 4].includes(reduxAccount?.data?.profile?.role)) {
       // retrieve-users based on selectedOrg
       JService.get(
           `/api/v1/auth/retrieve-users/${getOrgURLParam()}`
       )
         .then((res) => {
           if (!res.success)
             throw new Error(res?.message || "Could not retreive users");
           setUsers(res?.data || []); 
           const usersList = res?.data?.map((u) => ({
             name: u?.first_name + " " + u?.last_name,
             id: u?.id,
           }));
           usersList.push({id:'Unassigned', name:'No One'});
           dispatchAvailableData({
             type: "setPayloadToAllOfType",
             payload: {
               payloadFor: "assignedTo",
               payload: usersList
             },
           });  
         })
         .catch((err) =>{
           enqueueSnackbar(err?.message, {variant:'error'})
         }
         );
     }
   }
 
  useEffect(() => {
    fetchUsers();
  }, []);
  
  const handleAutoDialClick = (e) => {
    // console.log('autodial clicked')
    // dispatch(setReduxAutodialState({minimized: false, maximized: true }))
    // open the modal to set break time and
    // name of the autodial
    setCreateAutodialModalVisible(true);
  };

  const [createAutodialModalVisible, setCreateAutodialModalVisible] = useState(false);
  const [createAutodialModalValues, setCreateAutodialModalValues] = useState({
    interval: "",
    name: "",
  });

  // start autodial with values
  function handleCreateAutodialModalSave(e) {
    setCreateAutodialModalVisible(false);
    dispatch(
      setReduxAutodialState({
        minimized: false,
        maximized: true,
        ...createAutodialModalValues,
      })
    );
  }
  const intervalOptions = [5, 10, 15, 20];

  const createAutodialModalBody = createAutodialModalVisible ? (
    <Box>
      <form style={localStyles.form}>
        {/* name */}
        <label className="t7 nc-gray-900 medium-font" style={localStyles.createAutodialLabelName}>
          Name
          <input
            value={createAutodialModalValues?.name}
            type="text"
            className="t6 regular-font nc-gray-900 ui--input ui--name"
            style={localStyles.textInput}
            onChange={(e) =>
              setCreateAutodialModalValues(prev => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />
        </label>

        {/* gap between calls */}
        <label className="t7 nc-gray-900 medium-font" style={localStyles.createAutodialLabelGap}>
          Gap between calls
        </label>

        <Select
        value={createAutodialModalValues?.interval ? `${createAutodialModalValues.interval} sec` : ''}
            onChange={(e) =>
              setCreateAutodialModalValues(prev => ({
                ...prev,
                interval: e.target.value,
              }))
            }
            sx={commonStyles.select}
            placeholder="Select the gap between calls"
            renderValue={(selected) => {
              if (!selected) return <p className="regular-font t6 nc-gray-400">Select the gap between calls</p>;
              return <p className="regular-font t6 nc-gray-900">{selected}</p>;
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {intervalOptions.map(option => (
              <MenuItem
                key={option}
                value={option}
                sx={{
                  backgroundColor: createAutodialModalValues?.interval == option ? '#F0FCF4 !important' : 'white !important',
                  '&:hover': { backgroundColor: '#F0FCF4 !important' },
                }}
              >
                {`${option} sec`}
                {createAutodialModalValues?.interval == option && (
                  <span style={{ marginLeft: '80%' }}>{topNavAccountSelectedAccountTickIcon()}</span>
                )}
              </MenuItem>
            ))}
          </Select>

      </form>
    </Box>
  ) : null;

  function handleCreateAutodialModalClose(e) {
    // create autodial modal
    // cancel button / x icon clicked
    setCreateAutodialModalVisible(false);
    setCreateAutodialModalValues({
      interval: "",
      name: "",
    });
    setSelectionModel([]);
    setSelectedRows([]);
  }

  const handleContactDelete = (id) => {
    JService.delete(`/api/v1/core/delete-contacts/${listId}/`, {
      contacts: [id],
    })
      .then((res) => {
        if (!res?.success) throw new Error(res?.message)
		enqueueSnackbar(res?.message || 'Successfully deleted the contact', { variant: "success"})
		setShowDeletePrompt(false);
		fetchData();
      })
      .catch((err) => enqueueSnackbar(err?.message || "Error occurred while deleting contact from list", { variant: "error"}))
  };

  const handleContactEdit = (id) => {
    const payload = {
      name: editContactName,
    };

    if (!(
      !!rowBeingEdited &&
      !!rowBeingEdited.last_contacted &&
      Object.keys(rowBeingEdited.last_contacted).length === 0
    )) {
      payload["number"] = editContactNumber;
    }
    JService.patch(`/api/v1/core/update-contact/${listId}/${id}/`,
      payload
    )
      .then((res) => {
        if(!res?.success) throw new Error(res?.message)
        enqueueSnackbar(res?.message, { variant: "success"})
        
		setShowEditModal(false);
		fetchData();
      })
      .catch((err) => enqueueSnackbar(err?.message || "Error occurred while updating contact list", { variant: "error"}))
  };

  
  const handleAssigningContact = (id) => {
    const payload = {
      user_id: selectedUser.id,
      contact_list_id: Number(listId),
      
      contact_ids: selectedRows && Array.isArray(selectedRows) && selectedRows.length > 0 
        ? selectedRows.map(row => row.id) 
        : [id]
    };
    console.log(id, payload)

    JService.patch(`/api/v1/core/assign-contact/`, payload)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message);
        enqueueSnackbar(res?.message, { variant: "success" });
        setAssignToModal(false);
        setSelectionModel([]);
        setSelectedRows([]);
        setSelectedUser({});
        setUserSearchText('');
        fetchData();
      })
      .catch((err) => enqueueSnackbar(err?.message || "Error occurred while updating contact list", { variant: "error" }));
  };
  

  const handleUnassignContact = (id) => {
    const payload = {
      contact_list_id: Number(listId),
      contact_ids: selectedRows && Array.isArray(selectedRows) && selectedRows.length > 0 
        ? selectedRows.map(row => row.id) 
        : [id]
    };
    
    JService.patch(`/api/v1/core/unassign-contact/`, payload)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message);
        enqueueSnackbar(res?.message, { variant: "success" });
        setUnassignModal(false);
        setSelectionModel([]);
        setSelectedRows([]);
        setUserSearchText('');
        fetchData();
      })
      .catch((err) => enqueueSnackbar(err?.message || "Error occurred while updating contact list", { variant: "error" }));
  };
  
   const handleCloseAssignToModal=()=>{
    setSelectedUser({});
    setAssignToModal(false);
    setDropdownAnchorEl(null);
    setIsDropdownVisible(false);
   }
   const handleSelectUserDropdown = (e)=>{
    console.log(isDropdownVisible);
    setDropdownAnchorEl(e.currentTarget || null);
    setIsDropdownVisible((prevState) => !prevState);
   }
   const handleAssignedToFilterChange = (selected) => {
    dispatchAvailableData({
      type: "setPayloadToSelectedOfType",
      payload: {
        payloadFor: "assignedTo",
        payload: selected,
      },
    });
  };
  
   const handleDropdownClose=(e)=>{
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

	// clicked outside
    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
	
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      return;
    }
   }

   useEffect(()=>{
    if(isDropdownVisible) window.addEventListener("click", handleDropdownClose);
	  else window.removeEventListener("click", handleDropdownClose);

    return () => {
      window.removeEventListener("click", handleDropdownClose);
    };
   },[isDropdownVisible]);



  const onSearch = (searchText) => {
    const searchTextLower = searchText?.toLowerCase();
    const temp = masterRows?.filter((row) =>
      row?.name?.toLowerCase().includes(searchTextLower)
    );
    setRows(temp);
  };

  // const combineSearchAndFilters = (arg1) => {
  //   // if filters and search is empty
  //   if (
  //     !availableData?.statusTypes?.selected?.length &&
  //     availableData?.searchText.trim()?.length === 0
  //   ) {
  //     if (arg1 != rows) {
  //       setRows(arg1); //eslint-disable-line
  //     }
  //     return;
  //   }

  //   // callTypes selected changed
  //   if (availableData?.statusTypes?.selected?.length) {
  //     arg1 = arg1?.filter(
  //       (row) =>
  //         availableData?.statusTypes?.selected?.findIndex(
  //           (avs) =>
  //             avs ===
  //             row?.last_contacted?.call_type +
  //               " " +
  //               row?.last_contacted?.call_status
  //         ) >= 0
  //     );
  //   }

  //   // removing duplicates
  //   arg1 = arg1?.reduce(function (x, y) {
  //     if (x.indexOf(y) < 0) x.push(y);
  //     return x;
  //   }, []);

  //   setRows(arg1);
  // };

//   const combineSearchAndFilters = (arg1) => {
//   // if filters and search is empty
//   if (
//     !availableData?.statusTypes?.selected?.length &&
//     !availableData?.assignedTo?.selected?.length &&
//     availableData?.searchText.trim()?.length === 0
//   ) {
//     if (arg1 !== rows) {
//       setRows(arg1); // eslint-disable-line
//     }
//     return;
//   }

//   // callTypes selected changed
//   if (availableData?.statusTypes?.selected?.length) {
//     arg1 = arg1?.filter(
//       (row) =>
//         availableData?.statusTypes?.selected?.findIndex(
//           (avs) =>
//             avs ===
//             row?.last_contacted?.call_type +
//               " " +
//               row?.last_contacted?.call_status
//         ) >= 0
//     );
//   }

//   // assignedTo selected changed
//   if (availableData?.assignedTo?.selected?.length) {
//     arg1 = arg1?.filter(
//       (row) =>
//         (availableData?.assignedTo?.selected?.indexOf(row?.assigned_to?.[0]?.id) >= 0 || (availableData?.assignedTo?.selected?.includes('Unassigned') && !row?.assigned_to?.length))
//     );
//   }

//   // removing duplicates
//   arg1 = arg1?.reduce(function (x, y) {
//     if (x.indexOf(y) < 0) x.push(y);
//     return x;
//   }, []);

//   setRows(arg1);
// };

  const [showTooltip, setShowTooltip] = useState(true);

  const [showAutodialToolTip, setShowAutodialToolTip] = useState(false);

  const breadcrumbs = [
    <Link style={localStyles.breadcrumbLinkclr} to="/contact-lists">
      Contact lists
    </Link>,
    <Link
      style={localStyles.breadcrumbLink}
      to={
        "/contact-lists/" +
        (window.location.pathname.split("/").pop() || "1") +
        "/"
      }
    >
    <span style={{fontFamily:"inter", fontWeight:"500px"}}>{contentTitle || "-"}</span>  
    </Link>,
  ];

  const voiceBroadcastButton = (
    <Button
	  className="ui--button ui--vb"
      buttonText="Voice broadcast"
      icon={{
        startIcon:
          selectedRows.length === 0
            ? voiceBroadcastIcon("white", 20, 20)
            : voiceBroadcastIcon("black", 20, 20),
        endIcon: null,
      }}
      hierarchy="white"
      variant="outlined"
      disabled={selectedRows.length == 0}
      isLoading={false}
      onClick={() => setShowVoiceBroadcastModal(true)}
      buttonStyle={{ marginRight: "12px" }}
    />
  );

  const autodialButton = (
    <Button
	  className="ui--autodial ui--button"
      buttonText="Autodial"
      icon={{
        startIcon: (
          <img
            style={localStyles.autodialButtonStartIcon}
            src={
              selectedRows.length < 2
                ? // || selectedRows?.length > 100
                  autodialIconDisabled
                : autodialIcon
            }
            alt="autodial icon"
          />
        ),
        endIcon: null,
      }}
      hierarchy="white"
      variant="outlined"
      disabled={reduxAccount?.data?.license==='Viewer' || !reduxAccount?.data?.license ||selectedRows.length < 2}
      isLoading={false}
      onClick={handleAutoDialClick}
      buttonStyle={{ marginRight: "12px" }}
      tooltipMessage="Select atleast 2 contacts"
    />
  );

  const addContactButton = useMemo(
    () => (
      <Button
	    className="ui--add ui--button"
        buttonText="Add contact"
        icon={{
          startIcon: (
            <img style={localStyles.addContactButtonIcon} src={plus} alt="add contact icon" />
          ),
          endIcon: null,
        }}
        hierarchy="green"
        variant="outlined"
        disabled={false}
        isLoading={false}
        onClick={() => setShowAddContactModal(true)}
      />
    ),
    []
  );

  const updateInvalidContact = (contactId, changeType, newValue = null) => {
    if (changeType === "remove")
      setInvalidContacts(
        invalidContacts.filter((contact) => contact.id !== contactId)
      );
    else {
      setInvalidContacts(
        invalidContacts.map((contact) => {
          if (contact.id != contactId) {
            return contact;
          } else {
            if (changeType === "name-change")
              return { ...contact, first_name: newValue };
            else if (changeType === "phone-change")
              return { ...contact, phone_number: newValue };
          }
        })
      );
    }
  };

  const handleInvalidDelete = (contactId, showResponse = true) => {
    JService.delete(`/api/v1/core/delete-invalid-contacts/${listId}/`,
      {
        invalid_contacts: [contactId],
      }
    )
      .then((res) => {
		if(!res?.success) throw new Error(res?.message)

		updateInvalidContact(contactId, "remove");
        if(showResponse) enqueueSnackbar(res?.message, { variant: "success"})
      })
      .catch((err) => showResponse && enqueueSnackbar(err?.message || "Error occurred while deleting invalid contact", { variant: "error"}))
  };

  const getCountryCodeAndNumber = (ph) => {
    try {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        const number = phoneUtil.parseAndKeepRawInput(ph);
        return [number.getCountryCode(), number.getNationalNumber()];

    } catch(error) {
        console.error(error)
        return null
    }

  }

  const handleInvalidSave = (contactObj) => {
    const ccNumberPair = getCountryCodeAndNumber(contactObj.phone_number)
    const payload = {
        name: contactObj.first_name,
        country_code: "+91",
        number: contactObj.phone_number
    }
    if(ccNumberPair) {
        payload.country_code = `+${ccNumberPair[0]}`
        payload.number = `${ccNumberPair[1]}`
    }
    JService.post(`/api/v1/core/create-contact/${listId}/`, payload)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message)
		
		let newContact = res.data;
		newContact["last_contacted"] = {};
		setRows([newContact, ...rows]);
		setMasterRows([newContact, ...rows]);
		handleInvalidDelete(contactObj.id, false);

		enqueueSnackbar(res?.message, { variant: "success"})
      })
      .catch((err) => enqueueSnackbar(err?.message || "Error occurred while saving invalid contact", { variant: "error"}))
  };
  // useEffect(()=>{
  //   if(reduxVn && Object.keys(reduxVn)?.length){

  //     const {outbound_virtual_numbers, inbound_virtual_numbers}=reduxVn;
  //     const intInbound= inbound_virtual_numbers?.filter((vn)=>vn.country_code!=='+91')
  //     const smsEnabledNumbers= intInbound?.some((vn)=>outbound_virtual_numbers?.find(elem=>elem.id===vn.id))
  //     setMessagingEnabled(smsEnabledNumbers)
  //   }
  // },[reduxVn]);
useEffect(()=>{
  if(!users || !Array.isArray(users) || users.length===0) return;
  if(!userSearchText || !userSearchText.trim().length){
    setUsersToDisplay(users);
    return;
  }
  const temp = users.filter((user) => {
    const lowerCaseSearchText = (typeof userSearchText === "string" ? userSearchText.trim().toLowerCase() : "");
    return `${user.first_name} ${user.last_name}`.toLowerCase().includes(lowerCaseSearchText);
  });
  
  setUsersToDisplay(temp);

},[users, userSearchText]);

  const cols = [
    {
      field: "name",
      headerName: "Contact",
      flex: 1,
      sortable: false,
      
      renderCell: (params) => {
        return (
          <CustomTooltip
          tooltipTitle= {params.row.name || params.row.first_name}
           tooltipText={
            <>
              {params.row.name || params.row.first_name}
              <br />
              {!maskNumbers && (
                <span style={{userSelect: "all", fontSize: '14px', color: '#919994'}}>
                  {`${params?.row?.country_code}${params.row.number}`}
                </span>
              )}
              
            </>
            
          }>
          </CustomTooltip>
        );
        
        
      },
    },
    {
      field: "contactListViewPhoneButton",
      headerClassName: "user-table-header-user-name",
      headerName: "",
      cellClassName: "table-cell-edit",
      flex: 0.3,
      minWidth: 70,
      sortable: false,
      renderCell: (params) => (
		reduxAccount?.data?.license && reduxAccount?.data?.license!=='Viewer' && 
        <Box 
		  sx={localStyles.contactListViewPhoneButton}
          onClick={(e) => {
            e.stopPropagation();
            let _phone = params?.row?.number;
            // const phoneRegExp = new RegExp(/^(([+][\d]{1,4})?([\d]{10,12}))/gm);
            // const _res = phoneRegExp.exec(_phone);
            // if (Array.isArray(_res)) {
            dispatch(
              setDialer({
                //phone_number: _res.at(-1),
                phone_number: _phone,
                country_code: params?.row?.country_code,
                isVisible: true,
                contact_list_id: listId,
                maskNumbers
              })
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {sharedTablePhoneIcon(colors.ic_green_400)}
        </Box>
      ),
    },
    //  {
    //   field: "contactListViewPhoneButton",
    //   headerClassName: "user-table-header-user-name",
    //   headerName: "",
    //   cellClassName: "table-cell-edit",
    //   flex: 0.5,
    //   minWidth: 90,
    //   sortable: false,
    //   renderCell: (params) => (
    //     reduxAccount?.data?.license && reduxAccount?.data?.license!=='Viewer' &&
    //     <div className="table-cell-edit">
    //     <div>
    //     <span className='contactIcons' onClick={(e) => {
    //         e.stopPropagation();
    //         let _phone = params?.row?.phone_number;
    //         // const phoneRegExp = new RegExp(/^(([+][\d]{1,4})?([\d]{10,12}))/gm);
    //         // const _res = phoneRegExp.exec(_phone);
    //         // if (Array.isArray(_res)) {
    //         dispatch(
    //           setDialer({
    //             //phone_number: _res.at(-1),
    //             phone_number: _phone,
    //             country_code: params?.row?.country_code,
    //             isVisible: true,
    //           })
    //         );
    //         window.scrollTo({ top: 0, behavior: "smooth" });
    //       }} onMouseDown={(e) => e.stopPropagation()}>{callIcon()}</span>
    //     <span onClick={(e)=>messagingEnabled && !params?.row?.country_code==='+91' ? handleOpenConversation(e,params,'contacts'):null} disabled={!messagingEnabled || params?.row?.country_code==='+91'} className={`contactIcons ${!messagingEnabled || params?.row?.country_code==='+91' ? 'messageDisabled':''}`}>{messageIcon()}</span>
    //     </div>
    //   </div>
		
    //   ),
    // },
    
    {
      field: "last_contacted",
      headerName: "Last contacted",
      flex: 1.2,
      sortable: false,
      renderCell: (params) => {
		try {
			let value;
			if (params.row.last_contacted?.contacted_on) {
			  value = formatDate(params.row.last_contacted.contacted_on);
			} else value = "--";
	
			const arrowIdentifier = lastContactedToArrowMapping[params?.row?.last_contacted?.call_type][params?.row?.last_contacted?.call_status !== "unanswered" || "unanswered"] || null
			return (
			  <Box sx={localStyles.arrowContainer}>
				<div style={arrowMapping[arrowIdentifier].style}>
					{arrowMapping[arrowIdentifier].arrowIcon}
				</div>
	
				<span style={localStyles.contactedOnDate}>
				  {params?.row?.logsContactedOn?.date}
				</span>
	
				<p style={localStyles.contactedOn} className="nc-gray-900 t6">
				  {value}
				</p>
			  </Box>
			);
		}
		catch (err) {
			return  <Box sx={localStyles.arrowContainerNotContacted}>
				{/* if lastContacted is null */}
				{!params?.row?.last_contacted && "--"}
			</Box>
		}
      },
    },
    {
    field: "assigned_to",
    headerName: "Assigned to",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
        const assignedTo = params.row.assigned_to;

        if (assignedTo && assignedTo.length > 0) {
            const assignedToNames = assignedTo.map(person => `${person.first_name} ${person.last_name}`).join(', ');
            return <p style={{fontSize:'14px'}}>{assignedToNames}</p>;
        } else {
            return <p>--</p>;
        }
    },
},
    {
      field: "callHistory",
      headerName: "Call history",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <button
            className="row-view-btn"
			disabled={!params?.row?.last_contacted}
            onClick={(e) => {
              console.log(params?.row)
              localStorage.setItem(
                "frejun-interaction-history",
                JSON.stringify({
                  dset: true,
                  qread: false,
                  // data: {
                  //   title: contentTitle,
                  //   contact_data: params?.row
                  // },
                  data: params?.row,
                  cread: false,
                })
              );
              navigate("/call-logs");
            }}
          >
            View
          </button>
        );
      },
    },
    {
      field: "userEdit",
      headerName: "",
      flex: 0.5,
      sortable: false,
      cellClassName: "table-cell-edit", // @important use this threedots
      renderCell: (params) => {
        return (
          <Box>
            <img
			  className="pointer"
              onClick={(e) => {
                setRowOptionsCords([e["clientX"] - 136, e["clientY"] + 18]);
                e.stopPropagation();
                setRowBeingEdited(params.row);
                setRowOptionsVisible(true);
              }}
              src={threedotsIcon}
              alt="three dots icon"
            />
          </Box>
        );
      },
    },
  ];

  const handleScrollWhenRowOptionsOpen = (e) => {
    // scrolled
    // close rows options and detatch listener
    setRowOptionsVisible(false);
    window.removeEventListener("scroll", handleScrollWhenRowOptionsOpen);
  };

  // useEffect to attach event listener to close threedots (rowOptions)
  useEffect(() => {
    if (rowOptionsVisible)
      window.addEventListener("scroll", handleScrollWhenRowOptionsOpen);
    return () =>
      window.removeEventListener("scroll", handleScrollWhenRowOptionsOpen);
  }, [rowOptionsVisible]);

  // select all rows handlers
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [buttonPrefix, setButtonPrefix] = useState(0);
  const [_pageSize, _setPageSize] = useState(10);
  const [dropdownSelection, setDropdownSelection] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [buttonText, setButtonText] = useState("Clear selection");

  useEffect(() => {
    if (selectionModel.length === 0 && areAllSelected) {
      setAreAllSelected(false);
      setButtonPrefix(selectionModel.length);
      setShowTooltip(true);
      setButtonText(`Clear selection`);
    }

    if (selectionModel.length !== 0 && !areAllSelected) {
      setButtonPrefix(selectionModel.length);
      setButtonText("Clear selection");
      setAreAllSelected(true);
      setShowTooltip(selectionModel.length > 100 ? true : false);
    }
  }, [selectionModel]);


  

  
  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage + 1 !== pageData.currentPage) {
      setPageData((prev) => ({ ...prev, currentPage: newPage + 1 }));
    }
  };
  

function handleSelectAllRows() {
  if (buttonText.toLowerCase() === "clear selection") {
    setSelectionModel([]);
    setSelectedRows([]);
    setAreAllSelected(false);
    setButtonText("Select all in list");
  } else {
    const allRowIds = rows.map((r) => r.id);
    const updatedSelection = [...new Set([...selectionModel, ...allRowIds])];
    setSelectionModel(updatedSelection);
    setSelectedRows(updatedSelection.map((id) => rows.find((r) => r.id === id)));
    setButtonText("Clear selection");
    setAreAllSelected(true);
  }
}

function handleSelectionModelChange(newSelection) {
  const currentPageSelections = rows.filter((r) => newSelection.includes(r.id));
  const updatedSelection = [
    ...selectedRows.filter((row) => !rows.some((r) => r.id === row.id)),
    ...currentPageSelections,
  ].reduce((acc, row) => {
    if (!acc.some((selected) => selected.id === row.id)) acc.push(row);
    return acc;
  }, []);

  setSelectedRows(updatedSelection);
  setSelectionModel(updatedSelection.map((row) => row.id));
}

const handleHeaderCheckboxChange = (event) => {
  if (event.target.checked) {
    const newSelections = rows.map((r) => r.id);
    const updatedSelection = [...new Set([...selectionModel, ...newSelections])];
    handleSelectionModelChange(updatedSelection);
  } else {
    const updatedSelection = selectionModel.filter((id) => !rows.some((r) => r.id === id));
    handleSelectionModelChange(updatedSelection);
  }
};
  
  function handleDropdownChange(option) {
    if (option === 'selectAllInList') {
      setShowLoader(true);
  
      const queryParams = new URLSearchParams({
        all_contacts: true,
      });
  
      const url = `/api/v1/core/retrieve-contacts/${listId}/?${queryParams}`;
  
      JService.get(url)
        .then((res) => {
          if (!res?.success) throw new Error("Failed to fetch all contacts");
            const allContacts = res.data || [];
          if (!Array.isArray(allContacts)) {
            throw new Error("Invalid API response: data is not an array");
          }
          const allSelections = allContacts.map((contact) => contact.id);
          setSelectedRows(allContacts);
          setSelectionModel(allSelections);
          setButtonText("Clear selection");
          setAreAllSelected(true);
        })
        .catch((err) => {
          console.error("Error selecting all contacts:", err);
          enqueueSnackbar(
            err?.message || "An error occurred while selecting all contacts",
            { variant: "error" }
          );
        })
        .finally(() => setShowLoader(false))
    }
  
    setDropdownSelection("");
  }
  

  useEffect(() => {
    if (selectionModel.length === 0) {
      setDropdownSelection("");
    }
  }, [selectionModel]);

  useEffect(() => {
    if (!globalAutodialState?.maximized && selectedRows?.length !== 0) {
      setSelectionModel([]);
    }
  }, [globalAutodialState]);


  useEffect(()=>{
    console.log('selected rows', selectedRows);
  },[selectedRows]);
  const children = (
    <>
      {selectedRows.length > 0 && (
        <Box>
          <Box style={localStyles.selectAllContentContainer}>
          <FormControl>
            <Select
                IconComponent={ExpandMoreIcon}
                size="small"
                displayEmpty
                value={dropdownSelection}
                sx={{
                    marginBottom: "-2px",
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { borderColor: "#E1E5E2" },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E1E5E2",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E1E5E2",
                    },
                }}
                onChange={(e) => handleDropdownChange(e.target.value)}
            >
                <MenuItem value="" style={{ display: "none" }} disabled>
                    <span style={{ fontSize: "15.5px", color: "#464D48" }}>
                        {selectionModel.length}{"  "}contacts selected
                    </span>
                </MenuItem>
                <MenuItem value="selectAllInList" sx={{backgroundColor:"white !important", color:"#464D48"}}>
                    Select All in List
                </MenuItem>
            </Select>
        </FormControl>
            <Button
              className="ui--button ui--selectall"
              disabled={false}
              hierarchy="white"
              variant="outlined"
              buttonText={buttonText}
              isLoading={false}
              onClick={handleSelectAllRows}
              styleOverride={{
                marginLeft: "10px",
                paddingLeft: "12px",
                paddingRight: "12px",
                color: colors.ic_green_400,
                textDecoration: "underline",
                border: "none",
                ":hover": {
                  border: "none",
                  textDecoration: "underline",
                },
              }}
            />
            <Button
        className="ui--button ui--assign-to"
        disabled={false}
        hierarchy="white"
        variant="outlined"
        buttonText="Assign"
        isLoading={false}
        onClick={() => setAssignToModal(true)}
        styleOverride={{
          marginLeft: "10px",
          paddingLeft: "12px",
          paddingRight: "12px",
          color: colors.ic_green_400,
          textDecoration: "underline",
          border: "none",
          display: reduxAccount?.data?.profile?.role === 2 || reduxAccount?.data?.profile?.role === 3 ? "none" : "inline-block", 
          ":hover": {
            border: "none",
            textDecoration: "underline",
          },
        }}
      />
      <Button
        className="ui--button ui--assign-to"
        disabled={false}
        hierarchy="white"
        variant="outlined"
        buttonText="Unassign"
        isLoading={false}
        onClick={() => setUnassignModal(true)}
        styleOverride={{
          marginLeft: "10px",
          paddingLeft: "12px",
          paddingRight: "12px",
          color: colors.ic_green_400,
          border: "none",
          display: [1,4].includes(reduxAccount?.data?.profile?.role)? "inline-block" : "none" , 
          ":hover": {
            border: "none",
                },
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
      setSelectionModel([]);
    setSelectedRows([]);
    setButtonText("Select all in list"); 
    setAreAllSelected(false);
  
    setPageData((prev) => ({ ...prev, currentPage: 1 }));
  
    setDropdownSelection("");
  };
  
  
    return (
      <div className="screen-container">
        <GHeader
          breadcrumbs={{ isVisible: true, breadcrumbs }}
          threeDots={false}
          topHeader={{}}
          bottomHeader={{
            contentTitle: "Contacts",
            buttons: [
              // voiceBroadcastButton,
              ... (reduxAccount?.data?.access_list.includes("AUTODIAL")) ? [autodialButton] : [],
              addContactButton,
            ],
            isCalendar: false,
          }}
        />
  
        {/* TODO: when selected invalid contact disappears */}
        {invalidContacts.length > 0 && (
          <div className="invalid-contacts-notification t6 nc-gray-900">
            {invalidContactIcon()}
            &nbsp;{invalidContacts.length} Contact(s) were not added because of invalid
            name/phone number.&nbsp;
            <button onClick={() => setShowInvalidContactsModal(true)}>
              View
            </button>
          </div>
        )}
  
        {/* select all view */}
      
        <GTable
          leftHeader={{
            filters: filters,
            isSearch: true
          }}
          rightHeader={{isRefresh: true, isDownload: true }}
          download={{
            data: csvData,
            body: null,
            filename:
            `contact-list-${contentTitle || ""}-` +
              new Date().toLocaleString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }),
          }}
          checkboxVisibility={false}
          handlePageChange={handlePageChange}
          children={children}
          availableData={availableData}
          dispatchAvailableData={dispatchAvailableData}
          fromScreen="voice"
          onSearch={onSearch}
          rows={rows}
          headerCheckbox={
            <Checkbox
              sx={{
                zIndex: 100,
                '&.Mui-checked': {
                  color: '#2EB258',
                },
              }}
              checkedIcon={checkboxCheckedAll()}
              icon={checkboxUncheck()}
              checked={rows.length > 0 && rows.every((r) => selectionModel.includes(r.id))}
              onChange={handleHeaderCheckboxChange}
            />
          }
          
          columns={cols}
          headerCheckboxSelection={false}
          useMuiTable={true}
          isLoading={showLoader}
          getTableRowClassName={() => `teams-table-row`}
          additionalProps={{ bgHover: true }}
          onRowClick={() => {}}
          checkboxSelection={true}
          onSelectionModelChange={handleSelectionModelChange}
          handleSelectionModelChange={handleSelectionModelChange}
          // selectionModel={selectedRows.map(row=> {return row.id})}
          selectionModel={selectionModel}
          DataGridForcedPropsKey={"checkboxSelectionVisibleOnly"}
          buttonText={buttonText}
          paginationMode="server"
          rowCount={pageData.rowCount}
          gTablePageNumber={pageData.currentPage - 1}
          setGTablePageNumber={(newPage) => setPageData((prev) => ({ ...prev, currentPage: newPage }))}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPage={pageSize}
        />
  
        {/* Voice Broadcast Modal */}
        {/* <VoiceBroadcastModal
          type="NEW"
          visible={showVoiceBroadcastModal}
          hideModal={() => setShowVoiceBroadcastModal(false)}
          selectedRows={selectedRows}
          clearSelection={() => setSelectedRows([])}        
        /> */}
  
        {/* autodialNew TODO: on autodial cancel/vb cancel / finish reset selectedRows (to avoid rows selected but disabled buttons issue, until reload) */}
        <Autodial2 data={selectedRows} contactListId={listId}/>
        {/* autodialNew --end */}
  
        {/* Add Contacts Modal */}
        <AddContactModal
          visible={showAddContactModal}
          hideModal={() => setShowAddContactModal(false)}
          listId={listId}
          triggerReload={() => {
            fetchData();
            fetchInvalidContacts();
          }}
        />
  
        {/* Invalid Contacts Modal */}
        <GModal
          modalStyle={{
            width: "715px",
            maxHeight: "378px",
            padding: "24px 32px",
          }}
          closeModal={() => setShowInvalidContactsModal(false)}
          body={
            <div
              style={{
                border: "1px solid #E1E5E2",
                borderRadius: "6px",
              }}
            >
              {!!invalidContacts &&
                invalidContacts.length > 0 &&
                invalidContacts.map((c, index) => {
                  return (
                    <div key={index} className="invalid-contact">
                      <div
                        className="t6 nc-gray-900"
                        style={{ marginLeft: "24px" }}
                      >
                        {index + 1}
                      </div>
                      <input
                        type="text"
                        className="t6 nc-gray-900 ui--input ui--name"
                        onChange={(e) =>
                          updateInvalidContact(
                            c.id,
                            "name-change",
                            e.target.value
                          )
                        }
                        value={c?.first_name}
                        style={{
                          border: "1px solid transparent",
                          borderColor:
                            c.name?.trim().length === 0 ? "red" : "#E1E5E2",
                          marginLeft: "36px",
                        }}
                      />
                      <input
                        type="text"
                        className="t6 nc-gray-900 input ui--tel"
                        onChange={(e) => {
                          if (!isNaN(e.target.value) || e.target.value.includes("+"))
                            updateInvalidContact(
                              c.id,
                              "phone-change",
                              e.target.value
                            );
                        }}
                        value={c?.phone_number}
                        style={{
                          border: "1px solid transparent",
                          borderColor:
                            c.number?.length !== 10 ? "red" : "#E1E5E2",
                          marginLeft: "24px",
                        }}
                      />
  
                      <button
                      title="Update invalid contact"
                        className="invalid-contact-check-btn ui--button ui--save"
                        id={`error-contact-check-btn-${c.id}`}
                        onClick={() => handleInvalidSave(c)}
                        style={{ marginLeft: "36px" }}
                        disabled={
                          c?.first_name?.trim().length === 0 ||
                          c?.phone_number?.length < 10
                        }
                      >
                          <img src={saveIcon} alt="invalid contact save icon" />
                      </button>
                      <button
                          title="Delete invalid contact"
              className="ui--button ui--cancel"
                        style={{ marginLeft: "12px" }}
                        onClick={() => handleInvalidDelete(c.id)}
                      >
                        <img src={deleteIcon} alt="invalid contact save icon" />
                      </button>
                    </div>
                  );
                })}
            </div>
          }
          heading={"Edit Contact"}
          visible={showInvalidContactsModal}
          bottomButtons={[]}
        />
  
        {!!rowOptionsVisible && (
          <div
            className="row-options-backdrop"
            onClick={() => setRowOptionsVisible(false)}
          >
            <div style={localStyles.rowOptionsContainer}>
              <div
                className="row-options-foreground"
                style={{
                  marginLeft: rowOptionsCords[0],
                  marginTop: rowOptionsCords[1],
                  backgroundColor: '#fff',
                  width: '154px'
                }}
              >
                <button
                  className="t6 nc-gray-900 ui--button ui--edit"
                  style={localStyles.textAlignLeft}
                  onClick={() => {
                    // setEditListName(rowBeingEdited.job_title);
                    setEditContactName(rowBeingEdited.name);
                    setEditContactNumber(rowBeingEdited.number);
                    setShowEditModal(true);
                  }}
                >
                  Edit
                </button>
                {reduxAccount?.data?.profile?.role === 2 || reduxAccount?.data?.profile?.role === 3 ? null : (
                  <button
                    className="t6 nc-gray-900 ui--button ui--assignto"
                    style={localStyles.textAlignLeft}
                    onClick={() => {
                      setAssignToModal(true);
                    }}
                  >
                    Assign
                  </button>
                )}
                {[1,4].includes(reduxAccount?.data?.profile?.role) &&
                  <button
                    className="t6 nc-gray-900 ui--button ui--assignto"
                    style={localStyles.textAlignLeft}
                    onClick={() => {
                      setUnassignModal(true)
                    }}
                  >
                    Unassign
                  </button>
                }
                <button
                  className="t6 nc-gray-900 ui--button ui--delete"
                  style={localStyles.textAlignLeft}
                  onClick={() => {
                    setShowDeletePrompt(true);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
  
        {/* Edit Contact Modal */}
        <GModal
          modalStyles={{ width: "448px" }}
          closeModal={() => setShowEditModal(false)}
          heading={<p className="t6 semi-font">Edit contact</p>}
          visible={showEditModal}
          bottomButtons={[
            <Button
        className="ui--button ui--cancel"
              variant="outlined"
              buttonText="Cancel"
              icon={{}}
              disabled={false}
              isLoading={false}
              hierarchy="white"
              onClick={() => setShowEditModal(false)}
            />,
            <Button
        className="ui--button ui--save"
              variant="filled"
              buttonText="Save"
              icon={{}}
              isLoading={false}
              hierarchy="green"
              disabled={
                editContactName.length === 0 || editContactNumber.length != 10
              }
              onClick={() => handleContactEdit(rowBeingEdited.id)}
            />,
          ]}
          body={
            <form className="new-contact-list-form">
              <label className="t7 medium-font" style={{ marginBottom: "24px" }}>
                Name
                <input
                  placeholder="Enter Contact Name"
                  className="t6 regular-font ui--input ui--name"
                  name="name"
                  type="text"
                  onChange={(e) => {
                    setEditContactName(e.target.value);
                  }}
                  value={editContactName}
                />
              </label>
              {maskNumbers ? null : <label className="t7 medium-font" style={{ marginBottom: "32px" }}>
                Phone number
                <input
                  disabled={
                    !!rowBeingEdited &&
                    !!rowBeingEdited.last_contacted &&
                    Object.keys(rowBeingEdited.last_contacted).length != 0
                  }
                  placeholder="Enter Contact phone number"
                  className="t6 regular-font ui--input ui--phone"
                  name="name"
                  type="text"
                  onChange={(e) => {
                    setEditContactNumber(e.target.value);
                  }}
                  value={editContactNumber}
                />
              </label>}
            </form>
          }
        />
  
        {/* Assign contact Modal */}
        <GModal
          modalStyle={{minWidth:"368px", width: "368px",minHeight:'84px',height:'84px' }}
          bodyWrapperStyle={{width:'368px'}}
          closeModal={handleCloseAssignToModal}
          heading={<p className="t6 semi-font">{rowBeingEdited ? 'Assign 1 contact to':`Assign ${selectedRows.length} contacts to`} </p>}
          visible={showAssignToModal}
          bottomButtonsStyle={{borderTop:'none',paddingTop:'0'}}
          bottomButtons={[
            <Button
        className="ui--button ui--save"
              variant="filled"
              buttonText="Assign"
              icon={{}}
              isLoading={false}
              hierarchy="green"
              onClick={() => handleAssigningContact(rowBeingEdited && rowBeingEdited.id)}
            />,
          ]}
          body={
          <div style={{position:'relative'}}>
          <div
          className="filter"
          style={localStyles.gfilterContainer}
          onClick={(e) => handleSelectUserDropdown(e)}
        >
          <p className="b1 regular-font" style={{...localStyles.titleText, ...localStyles.pNoOverflow}}>
          {selectedUser.id ? `${(selectedUser.first_name + selectedUser.last_name)?.trim() ? selectedUser.first_name+ ' '+ selectedUser?.last_name : selectedUser?.email}`:'Select User'}
          </p>
          <img src={filterDownArrow} alt={"filter dropdown icon"} />
          </div>
          
          {isDropdownVisible && createPortal(<div className="assignToDropdown" style={localStyles.dropdown}>
          <div
                  style={{
                    backgroundColor: colors.nc_gray_100,
                    padding: "16px 20px",
                    borderBottom: "1px solid",
                    borderBottomColor: colors.nc_gray_300,
                    marginTop: "-8px",
                    position: "sticky",
                    zIndex: 3,
                    top: "0px",
                  }}
                >
                  <div
                    className="left-header-item search"
                    style={{
                      borderColor: colors.nc_gray_300,
                      maxHeight: "36px",
  
                      cursor: "pointer",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      padding: "8px",
  
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: colors.ic_white,
                    }}
                  >
                    <img
                      onClick={(e) =>
                        document.getElementById("gtable-search")?.focus()
                      }
                      style={{ marginRight: "5px" }}
                      src={userSearchText?.length > 0 ? searchActiveIcon : searchIcon}
                      alt="Search icon"
                    />
                    <input
                      id="gtable-search"
                      className="t6 regular-font"
                      type="search"
                      placeholder="Search"
                      value={userSearchText}
                      style={{ border: "1px solid #e1e5e2",borderLeft:'none',borderRight:'none',borderRadius:'0px', width: "100%" }}
                      onChange={(e) => setUserSearchText(e.target.value)}
                    />
                  </div>
                </div>
                <div style={{ overflowY: 'auto', maxHeight: '250px', overflowX:'hidden' }}>
                {usersToDisplay?.map((user) => (
                 <MenuItem
                 key={user.id}
                 onClick={(e)=>handleAssignedToFilterChange(e,user)}
                 sx={{
                   display: "flex",
                   flexDirection: "row",
                   alignItems: "center",
                   justifyContent: "space-between",
                   // '&.MuiMenuItem-root.Mui-selected': { background: '#F0FCF4'},
                   background: (selectedUser?.id === user.id
                     ? "#F0FCF4"
                     : "#fff"
                   ).concat(" !important"),
                 }}
                 value={user}
               >
                 <div style={{ padding: "10px 12px" }}>
                  {(user.first_name + user.last_name)?.trim() && <p
                     style={{ margin: 0 }}
                     className="t7 regular-font nc-gray-900 pNoOverflow"
                   >
                     {`${user.first_name} ${user.last_name}`}
                   </p>}
                   <p style={{ margin: 0 }} className="t7 regular-font nc-gray-600 pNoOverflow">{user.email}</p>
                 </div>
                 {selectedUser?.id === user?.id &&
                   topNavAccountSelectedAccountTickIcon()}
               </MenuItem>
                ))}

              </div>
             </div>, document.querySelector('.gmodal-background'))}
          </div>
        }
        />
  
        {/*Unassign Contacts Modal*/}
        <GModal visible={unassignModal} closeModal={()=>setUnassignModal(false)} heading="Unassign contact" 
                bottomButtons={[<Button variant="outlined" hierarchy='white' buttonText='Cancel' onClick={()=>setUnassignModal(false)}/>,
                                <Button variant="contained" hierarchy="green" buttonText="Unassign" onClick={()=>handleUnassignContact(rowBeingEdited && rowBeingEdited.id)}/>]}
                body={
                  <div>
                    <p className="t6 regular-font">Are you sure you want to unassign <u className="medium-font">{(selectedRows?.length) ? selectedRows.length : 1 } contact{selectedRows.length && selectedRows.length>1 ? 's': ''}</u>?</p>
                  </div>
                }
                bottomButtonsStyle={{borderTop:'none', paddingTop:'0'}}
                modalStyle={{marginBottom:'0'}}
        />
  
        {/* Delete Contact Modal */}
        <WarnModal
          visible={showDeletePrompt}
          closeModal={() => setShowDeletePrompt(false)}
          heading={<p className="t6 medium-font">Delete contact</p>}
          message={
            <div className="t6 regular-font">
              Are you sure you want to delete&nbsp;
              {!!rowBeingEdited && (
                <p
                  style={{
                    display: "inline",
                    textDecoration: "underline",
                    fontWeight: "500",
                  }}
                >
                  {rowBeingEdited.name}
                </p>
              )}
              &nbsp;from&nbsp;
              <p
                style={{
                  display: "inline",
                  textDecoration: "underline",
                  fontWeight: "500",
                }}
              >
                {contentTitle}
              </p>
              &nbsp;list ?
            </div>
          }
          actionButtonTitle="Delete"
          handleAction={() => handleContactDelete(rowBeingEdited.id)}
        />
  
        {/* create autodial modal */}
        <GModal
          disableCloseButton={showLoader}
          closeModal={handleCreateAutodialModalClose}
          body={createAutodialModalBody}
          heading={<span className="t6 nc-gray-900 medium-font">Autodial</span>}
          visible={createAutodialModalVisible}
          bottomButtons={[
            <Button
        className="ui--button ui--cancel"
              disabled={false}
              hierarchy="white"
              variant="contained"
              buttonText="Cancel"
              isLoading={false}
              onClick={handleCreateAutodialModalClose}
            />,
            <Button
          className="ui--button ui--start"
              disabled={
                createAutodialModalValues?.name === "" ||
                !createAutodialModalValues?.interval
              }
              hierarchy="green"
              variant="contained"
              buttonText="Start"
              isLoading={false}
              onClick={handleCreateAutodialModalSave}
            />,
          ]}
        />
         {/* <MessageHistoryModal payload={messageHistoryModal.payload} visible={messageHistoryModal.visible} id={messageHistoryModal.id} candidateDetails={messageHistoryModal.candidateDetails} viewOnly={messageHistoryModal.viewOnly} viewedMsgId={messageHistoryModal.viewedMsgId} onClose={handleCloseInteractionModal}/> */}
      </div>
    );
  }
  
  const localStyles = {
    form: { 
      display: "flex", 
      flexDirection: "column", 
      maxWidth: "100%" 
    },
    
    createAutodialLabelName: {
      marginBottom: "24px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
    },
    textInput: {
      marginTop: "4px",
      padding: "8px 12px",
      borderRadius: "4px",
      border: "1px solid #E1E6E2",
    },
    createAutodialLabelGap: { 
      marginBottom: "4px"
    },
    breadcrumbLink: { 
      color: colors.ic_green_400, 
    },
    breadcrumbLinkclr:{
      color: colors.nc_gray_900,
    },
    autodialButtonStartIcon: { 
      width: "18px", 
      pointerEvents: "none",
    },
    addContactButtonIcon: { 
      width: "18px", 
    },
  
    arrowContainer: {
      display: "flex",
      color: colors.nc_gray_900,
      ...styles.regular_font,
      ...styles.t6,
      alignItems: "center",
    },
    arrowContainerNotContacted: {
      color: colors.nc_gray_900,
      ...styles.regular_font,
      ...styles.t6,
      marginLeft: "50px",
    },
    arrowIn: {
      transform: "rotate(0deg)",
      display: "inline-block",
      marginTop: "8px",
    },
    arrowInMiss: { 
      transform: "rotate(0deg)",
      display: "inline-block",
    },
  
    contactedOnDate: { 
      marginLeft: "0px", 
      display: "inline-block",
    },
    contactedOn: { 
      paddingLeft: "12px", 
      fontSize:'14px'
    },
  
    selectAllContainer: {
      border: `1px solid ${colors.ic_green_400}`,
      borderRadius: "6px",
      marginBottom: "24px",
      height: "56px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "calc(100%)",
      paddingLeft: "18px",
      paddingRight: "18px",
      display: "flex",
    },
    selectAllContentContainer: {
      borderRadius: "6px",
      height: "56px",
      display: "flex",
      flexDirection: "row",
      position:'absolute',
      alignItems: "center",
      justifyContent: "center",
      userSelect: "none",
      marginLeft:'200px'
    },
    selectAllClose: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  
    rowOptionsContainer: {
      position: "relative",
      zIndex: 90,
    },
    textAlignLeft: {
      textAlign: "left" 
    },
    contactListViewPhoneButton: {
      /* Interface Colors/Green - 300 */
      border: "1px solid #34CB65",
      height: "36px",
      width: "36px",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    mui_select: {
      "&.MuiMenuItem-root.Mui-selected": {
        border: "1px solid red",
      },
      alignSelf: "flex-start",
      minWidth: 200,
      width: "100%",
      maxWidth: "100%",
      height: "40px",
      outline: "none",
      borderRadius: "4px",
      padding: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      ...styles.t6,
      ...styles.regular_font,
      color: colors.nc_gray_900,
      "&:before": {
        outline: "none",
        border: "none",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #34CB65",
        boxShadow: "0px 0px 0px 4px #F0FCF4",
      },
    },
  pNoOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "calc(100%)",
    margin: 0,
    lineHeight: "24px",
  },
  gfilterContainer: {
    border:`1px solid ${colors.nc_gray_300}`,
    marginRight: "12px",
    borderRadius: "4px",
    padding: "12px 10px 12px 12px",
    marginRight:0,
    cursor:  "pointer",
    position: "relative",
    zIndex: 1,
    maxHeight: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent:'space-between'
  },
  
  }
  
  const arrowMapping = {
    "arrowIn": {
      style: localStyles.arrowIn,
      arrowIcon: callLogsTableIncomingArrow(colors.ic_green_400)
    },
    "arrowInMiss": {
      style: localStyles.arrowInMiss,
      arrowIcon: callLogsTableIncomingArrow(colors.ic_red_400)
    },
    "arrowOut": {
      style: localStyles.arrowInMiss,
      arrowIcon: callLogsTableOutgoingArrow(colors.ic_green_400)
    },
    "arrowOutMiss": {
      style: localStyles.arrowInMiss,
      arrowIcon: callLogsTableOutgoingArrow(colors.ic_red_400)
    },
  }
  
  const lastContactedToArrowMapping = {
    "outbound": {
      "unanswered": "arrowOutMiss",
      true: "arrowOut"
    },
    "inbound": {
      "unanswered": "arrowInMiss",
      true: "arrowIn"
    }
  }